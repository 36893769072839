import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { FetchedProductType, ProductType } from '../../utils/types/productTypes';
import { upsellItem, VariantType } from '../productsState/productsTypes';
import {
  setLoader,
  toggleCart,
  setFinalPrice,
  setUpsellModal,
  addUpsellToCart,
  setCartProducts,
  addCartProduct,
  setProductsQuantity,
  toggleProtection,
  deleteCartProduct,
  setSelectedProduct,
  adjustMainProductQty,
  decrementMainProductQty,
  incrementMainProductQty,
  setSelectedProductIsSubscribed,
  setSubtotalPrice,
  setSelectedProductFrequency,
  setSelectedProductQuantity,
  deleteSelectedProduct,
  setTotalSavings,
  addBundleUpsellToCart,
  setSavingsQuantityPrice,
  setSelectedUpsell,
  setDefaultVariants,
  changeVariantColorState,
  changeVariantSizeState,
  setUpsells,
  setDefaultBundle,
  setBundleProduct,
  adjustUpsellQuantity,
  removeVariantUpsell,
  removeVariantMainProduct,
} from './slice';
import { cartProductItem } from './types';
import { calculateMainTotals, calculateUpsellsTotals } from 'utils/cartHelpers';

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useCartActions = () => {
  const dispatch = useAppDispatch();
  const toggleLoaderAction = () => dispatch(setLoader());
  const toggleCartAction = () => dispatch(toggleCart()); // *
  const setUpsellModalAction = (upsell: upsellItem | undefined) => dispatch(setUpsellModal(upsell)); // *
  const addUpsellToCartAction = (upsell: cartProductItem) => {
    dispatch(addUpsellToCart(upsell));
  };
  const setProductsQuantityAction = () => dispatch(setProductsQuantity());
  const toggleProtectionAction = () => dispatch(toggleProtection());
  const setTotalSavingsAction = () => dispatch(setTotalSavings());
  const setCartProductsAction = (cartProducts: ProductType[]) => dispatch(setCartProducts(cartProducts));
  const addCartProductAction = (cartProduct: ProductType) => dispatch(addCartProduct(cartProduct));
  const updateCartProductAction = (id: number, changes: Partial<ProductType>) =>
    dispatch(updateCartProduct({ id, changes }));
  const deleteCartProductAction = (id: number) => dispatch(deleteCartProduct(id));
  const setSelectedProductAction = (product: FetchedProductType, variant: any) =>
    dispatch(setSelectedProduct({ product, variant }));
  const setSelectedProductIsSubscribedAction = (isSubscribed: boolean) =>
    dispatch(setSelectedProductIsSubscribed(isSubscribed));
  const setSelectedProductFrequencyAction = (frequency: number) => dispatch(setSelectedProductFrequency(frequency));
  const setSelectedProductQuantityAction = (quantity: number) => dispatch(setSelectedProductQuantity(quantity));
  const deleteSelectedProductAction = () => dispatch(deleteSelectedProduct());
  const setSavingsQuantityPriceAction = () => dispatch(setSavingsQuantityPrice());
  const setDefaultVariantsAction = (maxAmount: number, colorVariants: VariantType, sizeVariants: VariantType) =>
    dispatch(setDefaultVariants({ colorVariants, sizeVariants, maxAmount }));
  const changeVariantSize = (type: string, value: string, label: string, id: string | number, extendedLabel: string) =>
    dispatch(changeVariantSizeState({ type, value, label, id, extendedLabel }));
  const changeVariantColor = (
    type: string,
    value: string,
    label: string,
    id: string | number,
    img: string,
    extendedLabel: string
  ) => dispatch(changeVariantColorState({ type, value, label, id, img, extendedLabel }));
  const setCartUpsellsAction = (upsellProduct: ProductType[], id: string) =>
    dispatch(setSelectedUpsell(upsellProduct, id));
  const removeUpsellFromCartAction = (id: string) =>
    dispatch((dispatch, getState) => {
      const state = getState();
      const filteredOutUpsells = state.cart.cartProducts?.upsells.filter((product) => product.product.id !== id);
      dispatch(setUpsells(filteredOutUpsells));
      calculateTotalsAction();
    });
  const decrementMainProductAction = (newProductObject: any, variantIndex: string) =>
    dispatch(decrementMainProductQty({ newProductObject, variantIndex }));
  const incrementMainProductAction = (newProductObject: any, variantIndex: string) =>
    dispatch(incrementMainProductQty({ newProductObject, variantIndex }));
  const calculateTotalsAction = () =>
    dispatch((dispatch, getState) => {
      const state = getState();
      const { mainProduct, upsells } = state.cart.cartProducts;
      const { freeShippingPrice } = state.cart;
      const { mainTotal, mainSavings } = calculateMainTotals(mainProduct.product, mainProduct.bundle);
      const { upsellsTotal, upsellsSavings } = calculateUpsellsTotals(upsells);
      let shippingCost = Number(state.cart.cartProducts.mainProduct.product.shipping_price);
      const subTotal = mainTotal + upsellsTotal;
      let total;
      if (Number(subTotal) > Number(freeShippingPrice)) {
        total = mainTotal + upsellsTotal;
      } else {
        total = mainTotal + upsellsTotal + shippingCost;
      }
      const savings = mainSavings + upsellsSavings;
      dispatch(setSubtotalPrice({ subTotal: subTotal }));
      dispatch(setFinalPrice({ total: parseFloat(total.toFixed(2)), savings: parseFloat(savings.toFixed(2)) }));
    });
  const changeBundlePriceAction = (
    value: string,
    label: string,
    parent_id: string,
    id: string,
    price: number,
    old_price: number,
    konnektive_id: string
  ) => dispatch(setDefaultBundle(value, label, id, parent_id, price, old_price, konnektive_id));
  const changeBundleProduct = (
    sku: string,
    label: string,
    id: string,
    parent_id: string,
    price: number,
    old_price: number,
    konnektive_id: string
  ) => {
    dispatch(setBundleProduct({ sku, label, id, parent_id, price, old_price, konnektive_id }));
  };
  const adjustUpsellQuantityAction = (id: string, newValue: number, variantId: number) => {
    dispatch(adjustUpsellQuantity({ id, newValue, variantId }));
  };
  const adjustMainProductQuantityAction = (newValue: number, variantId: string) => {
    dispatch(adjustMainProductQty({ newValue, variantId }));
  };
  const addBundleUpsellToCartAction = (upsell: any) => {
    dispatch(addBundleUpsellToCart({ upsell }));
  };

  const removeVariantsUpsellAction = (upsellId: string, variantId: string) => {
    dispatch(removeVariantUpsell({ upsellId, variantId }));
  };
  const removeVariantsMainProductAction = (variantId: string) => {
    dispatch(removeVariantMainProduct({ variantId }));
  };

  return {
    removeVariantsUpsellAction,
    toggleCartAction,
    setUpsellModalAction,
    addUpsellToCartAction,
    addBundleUpsellToCartAction,
    decrementMainProductAction,
    incrementMainProductAction,
    setCartProductsAction,
    addCartProductAction,
    updateCartProductAction,
    setProductsQuantityAction,
    toggleProtectionAction,
    deleteCartProductAction,
    setSelectedProductAction,
    setSelectedProductIsSubscribedAction,
    setSelectedProductFrequencyAction,
    setSelectedProductQuantityAction,
    deleteSelectedProductAction,
    setTotalSavingsAction,
    setSavingsQuantityPriceAction,
    changeVariantSize,
    changeVariantColor,
    setCartUpsellsAction,
    setDefaultVariantsAction,
    removeUpsellFromCartAction,
    calculateTotalsAction,
    changeBundlePriceAction,
    changeBundleProduct,
    adjustUpsellQuantityAction,
    adjustMainProductQuantityAction,
    removeVariantsMainProductAction,
    toggleLoaderAction,
  };
};
