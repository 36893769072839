import React, { lazy } from 'react';
import './index.css';
import './styles/styles.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useInView } from 'react-intersection-observer';
import { Header } from './components/Header/Header';
import Hero from './components/Hero/Hero';
import LazyCart from 'components/Cart/LazyCart';
import FloatingCTA from 'components/Floater/Floater';
import ProductHero from 'components/ProductHero/ProductHero';
import UpsellModal from 'components/UpsellModal/UpsellModal';
import Support from 'components/Zendesk';
import LazyComponent from 'components/LazyLaod';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { useCartSelectors } from 'lib/cartState/selectors';

const Layout = () => {
  const { Layout, Theme } = usewpdataSelectors();

  const { ref: heroRef, inView: isHeroVisible } = useInView({
    threshold: 0.1,
  });
  const { ref: headerRef, inView: isHeaderVisible } = useInView({
    threshold: 0.01,
  });

  const { isCartOpen, isLoading } = useCartSelectors();

  return (
    <>
      <div
        style={{
          '--button-custom-background': Theme?.customButton?.background,
          '--button-custom-backgroundHover': Theme?.customButton?.backgroudHover,
          '--button-custom-borderColor': Theme?.customButton?.borderColor,
          '--button-custom-textColor': Theme?.customButton?.textColor,
        }}
      >
        {Layout.hero?.layout !== 'lander' && <LazyCart isCartOpen={isCartOpen} />}
        <UpsellModal />
        <div style={{ width: '100%' }} ref={headerRef}>
          <Header />
        </div>
        {Layout?.hero?.display && (
          <div ref={heroRef}>
            {Layout.hero?.layout === 'lander' ? <Hero /> : <ProductHero isHeaderVisible={isHeaderVisible} />}
          </div>
        )}
        {Layout?.featuredIn?.display && (
          <LazyComponent Component={lazy(() => import('components/LogoSlider/LogoSlider'))} />
        )}
        {Layout?.products?.display && (
          <LazyComponent Component={lazy(() => import('components/ProductsSection/ProductSection'))} />
        )}
        {Layout?.videoTestimonials?.display && (
          <LazyComponent Component={lazy(() => import('components/VideoTestimonials/Testimonials'))}></LazyComponent>
        )}

        {Layout?.features?.display && (
          <LazyComponent Component={lazy(() => import('components/FeaturesList/FeaturesList'))} />
        )}
        {Layout?.setup?.display && <LazyComponent Component={lazy(() => import('components/ProductSetup/Setup'))} />}
        {Layout?.ribbon?.display && (
          <LazyComponent Component={lazy(() => import('components/SlidingTextBar/SlidingTextBar'))} />
        )}
        {Layout?.testimonialsSlider?.display && (
          <LazyComponent Component={lazy(() => import('components/TestimonialsSwiper/TestimonialSwiper'))} />
        )}
        {Layout?.competitorsComparison?.display && (
          <LazyComponent Component={lazy(() => import('components/CompetitorsComparison/CompetitorsComparison'))} />
        )}
        {Layout?.faq?.display && (
          <LazyComponent Component={lazy(() => import('components/FAQ/FAQ'))}>
            {Layout.awards?.display && (
              <LazyComponent Component={lazy(() => import('components/BoastBlock/QuoteBlock'))} />
            )}
            <LazyComponent Component={lazy(() => import('components/BoastBlock/ExpertBlock'))} />
          </LazyComponent>
        )}
        {Layout?.reviews?.display && <LazyComponent Component={lazy(() => import('components/CustomerReviews'))} />}
        <LazyComponent Component={lazy(() => import('components/Footer/Footer'))} />
        {Layout?.floater?.display && <FloatingCTA isHeroVisible={isHeroVisible} />}
        <div>
          <Support isHeroVisible={isHeroVisible} floaterVisible={Layout.floater?.display} />
        </div>
      </div>
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default Layout;
